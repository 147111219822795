.slick-slide {
    color: #B49270;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    padding: 20px 2px;
    // height: 80px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    &.slick-current {
        border-radius: 40px;
        background-color: #1D4068;
        color: #EFEBD8;
    }
}
.linear-gradient {
    background: rgb(255,255,255);
    z-index: 2;
    &.left {
        background: linear-gradient(90deg, #f7f4e5 35%, transparent);
    }
    &.right {
        background: linear-gradient(90deg, rgba(180, 146, 112, 0) 0%, #f7f4e5 100%)
    }
}