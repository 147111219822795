.gradient {
    // -webkit-filter: blur(9.6px);
    // filter: blur(9.6px);
    background-color: #0d406b;
    // background-image: linear-gradient(100deg, #b29bfc, #fd6bfd);
}

.text-pink {
    color: #FD6BFD;
}

// .text-gray {
//     color: #626262;
// }
// .text-green {
//     color: #02bc77;
// }
// .text-blue {
//     color: #2fa6f8;
// }
// .text-heart {
//     color: #fd605f;
// }
// .bg-green {
//     background-color: #02bc77;
// }
// .bg-blue {
//     background-color: #2fa6f8;
// }
// .bg-heart {
//     background-color: #fd605f;
// }
.border-text-gray {
    border-color: #626262;
    ;
}

.bg-black {
    background-color: #111111 !important;
}

svg>g[class^="raphael-group-"]>g[fill^="#b1b2b7"] {
    display: none;
}

.fusioncharts-container svg {
    touch-action: inherit !important;
}

// // over
// .react-datepicker__month-container,
// .react-datepicker {
//     width: 100%;
// }