.modal-alert {
    background-color: #FEFBEC;
    width: 90%;
    border: 1px solid #626262;
    // box-shadow: 0px 0px 15px #FEFBEC;    
    color: #B49270;
}

// .modal-window {
//     position: fixed;
//     background-color: rgba(255, 255, 255, 0.25);
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 999;
//     visibility: hidden;
//     opacity: 0;
//     pointer-events: none;
//     transition: all 0.3s;

//     &:target {
//         visibility: visible;
//         opacity: 1;
//         pointer-events: auto;
//     }

//     &>div {
//         width: 400px;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         padding: 2em;
//         background: white;
//     }

//     header {
//         font-weight: bold;
//     }

//     h1 {
//         font-size: 150%;
//         margin: 0 0 15px;
//     }
// }

// .modal-close {
//     color: #aaa;
//     line-height: 50px;
//     font-size: 80%;
//     position: absolute;
//     right: 0;
//     text-align: center;
//     top: 0;
//     width: 70px;
//     text-decoration: none;

//     &:hover {
//         color: black;
//     }
// }

// /* Demo Styles */

// // html,
// // body {
// //     height: 100%;
// // }

// // html {
// //     font-size: 18px;
// //     line-height: 1.4;
// // }

// // body {
// //     font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
// //         Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
// //     font-weight: 600;
// //     background-image: linear-gradient(to right, #7f53ac 0, #657ced 100%);
// //     color: black;
// // }

// // a {
// //     color: inherit;
// // }

// // .container {
// //     display: grid;
// //     justify-content: center;
// //     align-items: center;
// //     height: 100vh;
// // }

// // .modal-window {
// //     &>div {
// //         border-radius: 1rem;
// //     }
// // }

// // .modal-window div:not(:last-of-type) {
// //     margin-bottom: 15px;
// // }

// // small {
// //     color: lightgray;
// // }

// // .btn {
// //     background-color: white;
// //     padding: 1em 1.5em;
// //     border-radius: 1rem;
// //     text-decoration: none;

// //     i {
// //         padding-right: 0.3em;
// //     }
// // }