@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&family=Prompt:wght@400;500;600&display=swap');

// @import './tailwind.css';
@import "./extra.style.scss";

// @import "~/slick-carousel/slick/slick.css";
// @import "~/slick-carousel/slick/slick-theme.css";
html {
    scroll-behavior: smooth;
}

body {
    background-color: #F7F4E6 !important;
    font-family: "Poppins";
}

.font-prompt {
    font-family: "Prompt";
}

.font-poppins {
    font-family: "Poppins";
}

* {
    font-family: "Poppins";
}

// ion-app {
//     --justify-content: start;
// }
ion-content {
    --background: var(--ion-background-color, transparent);
    // --padding-bottom: 40px;
    // --padding-end: 20px;
    // --padding-start: 20px;
    // --padding-top: 40px;
}

.absolute {
    &.center-x {
        left: 50%;
        transform: translateX(-50%);
    }

    &.center-y {
        top: 50%;
        transform: translateY(-50%);
    }

    &.center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}


::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #F0EEE5;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #F0EEE5;
}