// .lds-circle {
//   display: inline-block;
//   // transform: translateZ(1px);
//   // background-color: red;
//   position: absolute;
//   z-index: 33;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }
// .lds-circle > div {
//   display: inline-block;
//   width: 64px;
//   height: 64px;
//   margin: 8px;
//   border-radius: 50%;
//   // background: red;
//   background: linear-gradient(90deg, black 0%, #5d666e 100%);
//   animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
// }
// @keyframes lds-circle {
//   0%, 100% {
//     animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
//   }
//   0% {
//     transform: rotateY(0deg);
//   }
//   50% {
//     transform: rotateY(1800deg);
//     animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
//   }
//   100% {
//     transform: rotateY(3600deg);
//   }
// }



.spinner {
  width: 80px;
  height: 80px;
  // background-color: #333;
  background: linear-gradient(90deg, black 0%, #5d666e 100%);
  // margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // z-index: 10;
  margin-left: -30px;
  margin-top: -60px;
  z-index: 99999;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}