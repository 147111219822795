.header-theme {
    height: 70px;
    // display: flex;
    // background-color: red;
    // justify-content: space-between;
    // background-color: black;
    // border-bottom: 1px solid black;
    position: relative;
    .fixed {
        // position: fixed;
        width: 100%;
        // background: #fafafa;
        // top: 0px;
        // left: 0px;
        height: 60px;
        padding: 10px 20px;
        @media (max-width: 400px) {
            padding: 0 10px;
        }

        z-index: 3;
        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 190px;
        }
    }

    .menu-icon {
        width: 30px;
    }

    .title_icon {
        width: 30px;
        margin-right: 5px;
    }

    .title_style {
        ion-img {
            display: inline-block;
        }
    }
}